jQuery(window).on("scroll", function() {
  let length = 0;
  if (jQuery(".mv").length) {
    length = jQuery(".mv").innerHeight();
  }
  if (length < jQuery(this).scrollTop()) {
    jQuery("body").attr("header-scroll", "true");
  } else {
    jQuery("body").attr("header-scroll", "false");
  }
});
